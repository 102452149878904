import React from "react";
import PropTypes from "prop-types";
import MainAction from "components/MainAction/MainAction.js";

export default function GenericMainAction(props) {
  const { routes, tsService, policy_type } = props;

  return (
    <div>
      <MainAction
        policy_type={policy_type}
        routes={routes}
        tsService={tsService}
      />
    </div>
  );
}

GenericMainAction.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  tsService: PropTypes.object,
  policy_type: PropTypes.string,
};
