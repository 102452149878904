import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TransmitContainer from "views/TransmitContainer/TransmitContainer.js";
import { removeElement, addStyle } from "utils/utils.js";

export default function ConfigureAuthenticators(props) {
  const { tsService } = props;
  const id = "xmui-override";
  const textContent = `:root { 
      --xmui-primary-color: ${tsService.routes[0].commands[0].user_association.branding.primary_color
        .replace("rgb(", "")
        .replace(")", "")};
      --xmui-secondary-color: ${tsService.routes[0].commands[0].user_association.branding.secondary_color
        .replace("rgb(", "")
        .replace(")", "")};
      };
  }`;

  useEffect(() => {
    if (!tsService.getSessionToken()) {
      if (window.location.href.indexOf("/home/user_login") === -1) {
        tsService.onCancel();
      }
    } else {
      tsService
        .startAuthenticationConfiguration()
        .then((res) => {
          console.log("SUCCESS", res);
          tsService.onJourneyComplete();
        })
        .catch((err) => {
          console.log("ERROR", err);
          tsService.onJourneyComplete();
        });
    }
  });
  if (document.getElementById("xmui-override") !== undefined) {
    removeElement("xmui-override");
    addStyle(id, textContent);
  }
  return (
    <div>
      <TransmitContainer />
    </div>
  );
}

ConfigureAuthenticators.propTypes = {
  tsService: PropTypes.object,
};
