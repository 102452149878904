import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Grid, TextField } from "@material-ui/core";
import { SketchPicker } from "react-color";
import SettingsFooter from "components/SettingsFooter/SettingsFooter.js";
import "components/BrandingSettings/BrandingSettings.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "8vw",
    },
  },
}));

export default function BrandingSettings(props) {
  const {
    routes,
    handleBrandingChanges,
    isMultilineField,
    resetSettings,
    handleFileImport,
    downloadSettings,
    handleUpdates,
  } = props;

  const classes = useStyles();
  const [sketchColor, setsketchColor] = React.useState([]);
  const [colorId, setcolorId] = React.useState([]);

  const handleColorChange = (event) => {
    if (
      event.target.id.includes("primary_color") ||
      event.target.id.includes("secondary_color")
    ) {
      setsketchColor(event.target.value);
      setcolorId(event.target.id);
      document.getElementById("sketch-color").style.display = "block";
    } else {
      document.getElementById("sketch-color").style.display = "none";
    }
  };

  const handleColorChangeComplete = (color) => {
    setsketchColor(color.rgb);
    if (colorId.includes("primary_color")) {
      routes[0].commands[0].user_association.branding.primary_color =
        "rgb(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + ")";
      localStorage.setItem("TS", JSON.stringify(routes));
      handleUpdates();
    } else if (colorId.includes("secondary_color")) {
      routes[0].commands[0].user_association.branding.secondary_color =
        "rgb(" + color.rgb.r + "," + color.rgb.g + "," + color.rgb.b + ")";
      localStorage.setItem("TS", JSON.stringify(routes));
      handleUpdates();
    }
    document.getElementById("sketch-color").style.display = "none";
  };

  return (
    <Grid>
      <GridContainer>
        <GridItem xs={"auto"} sm={"auto"} md={"auto"}>
          <li className="header-title">BRANDING SETTINGS</li>
          <Card
            variant="outlined"
            style={{
              marginLeft: "0.5vw",
            }}
          >
            <CardBody style={{ width: "74.5vw" }}>
              <div id="sketch-color">
                <SketchPicker
                  color={sketchColor}
                  onChangeComplete={handleColorChangeComplete}
                />
              </div>
              <form
                className={classes.root}
                noValidate
                autoComplete="off"
              ></form>
              {Object.entries(
                routes[0].commands[0].user_association.branding
              ).map((key) => {
                return (
                  <TextField
                    key={"text-branding-key-" + key[0]}
                    id={"text-branding-id-" + key[0]}
                    label={key[0]}
                    value={key[1]}
                    multiline={isMultilineField(key[0])}
                    onChange={(event) =>
                      handleBrandingChanges(event, routes, key[0])
                    }
                    onClick={(event) => {
                      handleColorChange(event);
                    }}
                    fullWidth
                    margin="normal"
                  />
                );
              })}
            </CardBody>
          </Card>
          <SettingsFooter
            resetSettings={resetSettings}
            handleFileImport={handleFileImport}
            downloadSettings={downloadSettings}
          ></SettingsFooter>
        </GridItem>
      </GridContainer>
    </Grid>
  );
}

BrandingSettings.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  handleBrandingChanges: PropTypes.func,
  isMultilineField: PropTypes.func,
  resetSettings: PropTypes.func,
  handleFileImport: PropTypes.func,
  downloadSettings: PropTypes.func,
  handleUpdates: PropTypes.func,
};
