import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./BindId.css";

export default function Bindid(props) {
  const { routes, tsService } = props;

  const authenticate = () => {
    tsService.authenticateBindId();
  };

  useEffect(() => {
    authenticate();
  });

  return <div id="bind-id-div"></div>;
}

Bindid.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  tsService: PropTypes.object,
};
