import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Grid, TextField } from "@material-ui/core";
import "./ProductSettings.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "8vw",
    },
  },
}));

export default function ProductSettings(props) {
  const { products, handleProductSettingsChanges } = props;

  const classes = useStyles();

  return (
    <Grid>
      <GridContainer>
        <GridItem xs={"auto"} sm={"auto"} md={"auto"}>
          <li className="header-title">PRODUCTS SETTINGS</li>
          <Card
            variant="outlined"
            style={{
              marginLeft: "0.5vw",
            }}
          >
            <CardBody style={{ width: "74.5vw" }}>
              <form
                className={classes.root}
                noValidate
                autoComplete="off"
              ></form>
              {products.map((prop, key) => {
                console.log(prop);
                return (
                  <div
                    id={"product-wrapper-div-" + key}
                    key={"product-wrapper-key-" + key}
                    className="product-wrapper-div"
                  >
                    <TextField
                      key={"text-products-key-title-" + key}
                      id={"text-products-id-title-" + key}
                      label={"Title"}
                      value={prop.name}
                      multiline={false}
                      onChange={(event) =>
                        handleProductSettingsChanges(
                          event,
                          products,
                          prop.id,
                          "name"
                        )
                      }
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      key={"text-products-key-quantity-" + key}
                      id={"text-products-id-quantity-" + key}
                      label={"Quantity"}
                      value={prop.available_quantity}
                      multiline={false}
                      onChange={(event) =>
                        handleProductSettingsChanges(
                          event,
                          products,
                          prop.id,
                          "available_quantity"
                        )
                      }
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      key={"text-products-key-price-" + key}
                      id={"text-products-id-price-" + key}
                      label={"Price"}
                      value={prop.price}
                      multiline={false}
                      onChange={(event) =>
                        handleProductSettingsChanges(
                          event,
                          products,
                          prop.id,
                          "price"
                        )
                      }
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      key={"text-products-key-description-" + key}
                      id={"text-products-id-description-" + key}
                      label={"Description"}
                      value={prop.description}
                      multiline={false}
                      onChange={(event) =>
                        handleProductSettingsChanges(
                          event,
                          products,
                          prop.id,
                          "description"
                        )
                      }
                      fullWidth
                      margin="normal"
                    />
                  </div>
                );
              })}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Grid>
  );
}

ProductSettings.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  handleProductSettingsChanges: PropTypes.func,
};
