import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TransmitContainer from "views/TransmitContainer/TransmitContainer.js";
import { removeElement, addStyle } from "utils/utils.js";

export default function CustomAction(props) {
  const { routes, tsService, policy_type } = props;

  const id = "xmui-override";
  const textContent = `:root { 
      --xmui-primary-color: ${routes[0].commands[0].user_association.branding.primary_color
        .replace("rgb(", "")
        .replace(")", "")};
      --xmui-secondary-color: ${routes[0].commands[0].user_association.branding.secondary_color
        .replace("rgb(", "")
        .replace(")", "")};
      };
  }`;

  useEffect(() => {
    if (!tsService.getSessionToken()) {
      if (window.location.href.indexOf("/home/user_login") === -1) {
        tsService.onCancel();
      }
    } else {
      customAction(policy_type);
      if (document.getElementById("xmui-override") !== undefined) {
        removeElement("xmui-override");
        addStyle(id, textContent);
      }
    }
  });

  const customAction = (policy_type) => {
    if (!tsService.getSessionToken()) {
      if (window.location.href.indexOf("/home/user_login") === -1) {
        tsService.onCancel();
      }
    }

    var tsprops = tsService.buildTsProps(
      policy_type,
      routes[0].commands[0].user_association.custom_actions
    );

    tsService
      .cancelCurrentRunningControlFlow()
      .then((result) => {
        tsService
          .invokePolicy(tsprops.policyId, tsprops.params, tsprops.clientContext)
          .then((result) => {
            console.log(
              `TS journey - ${tsprops.policyId} successfully executed`
            );
            tsService.onJourneyComplete();
          })
          .catch((err) => {
            console.log(
              `TS journey - ${tsprops.policyId}  execution failed : \n ` + err
            );
            if (err._errorCode === 12) {
              alert("Session Expired.");
              tsService.onCancel();
            } else {
              tsService.handleAuthError(err).then((msg) => {
                if (msg === "retry") {
                  customAction(policy_type);
                } else {
                  tsService.showError(err);
                }
              });
            }
          });
      })
      .catch((err) => {
        console.log(
          `TS journey - ${tsprops.policyId}  execution failed : \n ` + err
        );
        tsService.handleAuthError(err).then((msg) => {
          if (msg === "retry") {
            customAction(policy_type);
          } else {
            tsService.showError(err);
          }
        });
      });
  };

  return (
    <div>
      <TransmitContainer />
    </div>
  );
}

CustomAction.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  tsService: PropTypes.object,
  policy_type: PropTypes.string,
};
