export default class TransmitService {
  constructor(products, routes, uiHandlerType, history) {
    this.history = history;
    this.sdk = window.xmsdk.XmSdk();
    this.defaultXmui = window.xmui;
    this.xmui = window.custom.CustomUIHandler;
    this.XmBindId = window.XmBindId;
    this.serverUrl = routes[0].commands[0].settings.serverUrl;
    this.appId = routes[0].commands[0].settings.appId;
    this.apiTokenId = routes[0].commands[0].settings.apiTokenId;
    this.apiToken = routes[0].commands[0].settings.apiToken;
    this.realm = routes[0].commands[0].settings.realm;
    this.uiHandlerType = uiHandlerType;
    this.session_storage_key = "transmit_token";
    this.routes = routes;
    this.products = products;
    this.clientId = routes[0].commands[0].bind_id.clientId;
    this.apiCompat = routes[0].commands[0].bind_id.apiCompat;
    this.redirectUri = routes[0].commands[0].bind_id.redirectUri;
    this.client_secret = routes[0].commands[0].bind_id.client_secret;
  }

  startInit() {
    var othis = this;
    return new Promise((resolve, reject) => {
      var connectionSettings = window.com.ts.mobile.sdk.SDKConnectionSettings.create(
        othis.serverUrl,
        othis.appId
      );

      if (othis.realm) {
        connectionSettings.setRealm(othis.realm);
      }
      othis.sdk.setLogLevel(window.com.ts.mobile.sdk.LogLevel.Debug);
      othis.sdk.setConnectionSettings(connectionSettings);

      switch (othis.uiHandlerType) {
        case "custom":
          othis.sdk.setUiHandler(new othis.xmui());
          break;
        default:
          othis.sdk.setUiHandler(new othis.defaultXmui.XmUIHandler());
      }

      this.installTransmitPlugins();

      othis.sdk
        .initialize()
        .then((res) => {
          console.log("SDK Initialized succesfuly:", res);

          if (
            window.custom.googleIdpResponse !== "" &&
            window.custom.googleIdpResponse !== null
          ) {
            othis.resumeControlFlow();
            resolve(true);
            return;
          }

          if (!othis.getSessionToken()) {
            if (window.location.href.indexOf("/home/user_login") === -1) {
              othis.onCancel();
            }
          }
          resolve(true);
        })
        .catch((err) => {
          console.error(`Initialization error uccured: ${err.getMessage()}`);
          reject(err);
        });
    });
  }

  startAuth(selectedUser, policyId, parms, clientContext) {
    console.log("startAuth");
    var othis = this;
    return new Promise((resolve, reject) => {
      othis.sdk
        .authenticate(selectedUser, policyId, parms, clientContext)
        .then((successfulAuth) => {
          resolve(successfulAuth);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  invokeAnonymousPolicy(policyId, params, clientContext) {
    var othis = this;
    return new Promise((resolve, reject) => {
      othis.sdk
        .invokeAnonymousPolicy(policyId, params, clientContext)
        .then((successfulAuth) => {
          resolve(successfulAuth);
        })
        .catch((err) => {
          if (!othis.getSessionToken()) {
            if (window.location.href.indexOf("/home/user_login") === -1) {
              othis.onCancel();
            }
          }
          reject(err);
        });
    });
  }

  invokePolicy(policyId, params, clientContext) {
    var othis = this;

    return new Promise((resolve, reject) => {
      othis.sdk
        .invokePolicy(policyId, params, clientContext)
        .then((results) => {
          resolve(results);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  startAuthenticationConfiguration() {
    var othis = this;
    console.log("START CONFIGURATION");
    return new Promise((resolve, reject) => {
      othis.sdk
        .startAuthenticationConfiguration({
          uiContainer: document.getElementById("transmitContainer"),
          disablePasswordAutocomplete: true,
        })
        .then(
          (success) => {
            console.log("Done handling Config Menu, returning to main page");
            resolve(success);
          },
          function(err) {
            var message;
            if (err) {
              var text =
                "Config menu error: " + (err.getMessage && err.getMessage()) ||
                "[NO-ERROR-MESSAGE]";
              var data = JSON.stringify(err.getData(), null, 2);
              message = text + "\nAdditional data: \n" + data;
            } else {
              message = "Unknown error occurred while evaluating Config Menu";
            }
            reject(err);
          }
        );
    });
  }

  doLogout() {
    var othis = this;
    return new Promise((resolve, reject) => {
      othis
        .cancelCurrentRunningControlFlow()
        .then((result) => {
          othis.sdk
            .logout()
            .then(() => {
              othis.updateSessionToken(null);
              resolve(true);
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  handleAuthError(err) {
    var othis = this;
    return new Promise((resolve, reject) => {
      if (err.getErrorCode && err.getErrorCode() === 8) {
        othis
          .doLogout()
          .then(() => {
            resolve("retry");
          })
          .catch((logout_err) => {
            console.log(
              "Error logging out of Transmit session: \n" + logout_err
            );
            resolve("fatal");
          });
      } else {
        resolve("fatal");
      }
    });
  }

  onLogin() {
    this.history.push("/admin/dashboard");
  }

  onJourneyComplete() {
    this.history.push("/admin/dashboard");
  }

  onCancel() {
    this.history.push("/home/user_login");
  }

  getContainer(id) {
    return document.getElementById(id);
  }

  getUsername() {
    return document.getElementById("username").value;
  }

  cancelCurrentRunningControlFlow() {
    var othis = this;
    return new Promise((resolve, reject) => {
      othis.sdk.cancelCurrentRunningControlFlow();
      setTimeout(function() {
        resolve(true);
      }, 10);
    });
  }

  showError(err) {
    var text = "Error: " + (err.getMessage && err.getMessage());
    var data = JSON.stringify(err.getData && err.getData(), null, 2);
    console.log(text + "\nAdditional data: \n" + data);
    if (err.getErrorCode() === 0) {
      alert(err.getMessage());
    }
  }

  getInvokeType(actions, policy_type) {
    let invoke_type = "";
    actions
      .filter((prop) => prop.type === policy_type)
      .map((prop) => {
        invoke_type = prop.invoke_type;
      });
    return invoke_type;
  }

  buildTsProps(policy_type, actions, params) {
    var tsProps = {
      username: "",
      policyId: "",
      transmitContainer: "",
      loginContainer: "",
      forgotUserNameContainer: "",
      clientContext: "",
      params: params,
    };

    actions.map((prop) => {
      if (prop.type === policy_type) {
        tsProps.policyId = prop.policy_id;
      }
    });

    tsProps.username = policy_type === "user_login" ? this.getUsername() : "";
    tsProps.loginContainer = this.getContainer("loginContainer");
    tsProps.forgotUserNameContainer = this.getContainer(
      "forgotUserNameContainer"
    );
    tsProps.transmitContainer = this.getContainer("transmitContainer");

    tsProps.clientContext = {
      uiContainer: tsProps.transmitContainer,
      username: tsProps.username,
    };
    return tsProps;
  }

  updateSessionToken(token) {
    if (!token) {
      sessionStorage.removeItem(this.session_storage_key);
      return;
    }
    sessionStorage.setItem(this.session_storage_key, token);
  }

  getSessionToken() {
    return sessionStorage.getItem(this.session_storage_key);
  }

  getRandomInt() {
    return Math.floor(Math.random() * (Math.pow(2, 31) - 1));
  }

  initializeBindIdSDK() {
    try {
      this.XmBindId.initialize({
        clientId: this.clientId,
        apiCompat: this.apiCompat,
      });
      console.log("BIND SDK INITIALIZED SUCCESSEFULLY");
    } catch (error) {
      console.log("BIND SDK INITIALIZATION FAILED", error);
    }
  }

  authenticateBindId() {
    try {
      console.log("AUTHENTICATE WITH BIND SDK", this.redirectUri);
      this.XmBindId.authenticate({
        redirectUri: this.redirectUri,
        nonce: this.getRandomInt(),
        state: String(this.getRandomInt()),
      });
    } catch (error) {
      console.log("BIND AUTHENTICATION FAILED", error);
      alert("BIND AUTHENTICATION FAILED");
    }
  }

  /**
   * Installs transmit plugins
   */
  installTransmitPlugins() {
    this.sdk.installPlugin("idp-google", {});
    // this.sdk.pluginManager.initializePlugins();
    // this.sdk.installPlugin('BioCatchRiskEngine', {});
  }

  /**
   * Resumes suspended control flow  (Google idp)
   */
  resumeControlFlow() {
    var othis = this;

    const suspensionContext = null;
    const resumptionParameters = {
      redirectHash: window.custom.googleIdpResponse,
    };

    othis.getContainer("loginContainer").style.display = "none";

    othis.sdk
      .resumeSuspendedControlFlow(suspensionContext, resumptionParameters, {})
      .then(function(successfulAuth) {
        console.log(
          `Resuming suspended journey successfully executed ` +
            successfulAuth.getToken()
        );
        const token = successfulAuth.getToken();
        othis.updateSessionToken(token);
        othis.onLogin();
      })
      .catch(function(err) {
        othis.onCancel();
      });
  }
}
