export function hideShoppingCart() {
  const divsToHide = ["products", "cart", "checkout"];
  for (let div of divsToHide) {
    document.getElementById(div).style.display = "none";
  }
}

export function showProducts() {
  hideShoppingCart();
  document.getElementById("products").style.display = "block";
}

export function showCart() {
  console.log("SHOW CART");
  hideShoppingCart();
  document.getElementById("cart").style.display = "block";
}

export function showCheckout() {
  hideShoppingCart();
  document.getElementById("checkout").style.display = "block";
}

export function removeElement(elementId) {
  var element = document.getElementById(elementId);
  if (element != undefined) {
    element.parentNode.removeChild(element);
  }
}

export function addStyle(id, textContent) {
  const css = document.createElement("style");
  css.type = "text/css";
  css.id = id;
  css.textContent = textContent;
  document.head.appendChild(css);
}

export function addScript(src, id) {
  console.log(src);
  const script = document.createElement("script");
  script.id = id;
  script.type = "text/javascript";
  script.src = src;
  script.async = true;
  document.head.appendChild(script);
}

export function getRoute() {
  return window.location.pathname !== "/admin/maps";
}

export function getSignInRoute() {
  return window.location.pathname === "/home/user_login";
}

export function getDashboardRoute() {
  return window.location.pathname === "/admin/dashboard";
}

export function getCart() {
  let lsCart = localStorage.getItem("cart");
  if (lsCart) {
    lsCart = JSON.parse(lsCart);
    return lsCart;
  }

  return null;
}

export function getCartCount() {
  let lsCart = localStorage.getItem("cart");
  let count = 0;
  if (lsCart) {
    lsCart = JSON.parse(lsCart);
    Object.values(lsCart).forEach((item) => (count += item));
  }

  return count;
}

export function hideMainActionField(fieldName) {
  const hiddenFields = ["visible"];
  return hiddenFields.includes(fieldName);
}

export function hideCustomActionField(fieldName) {
  const hiddenFields = ["branding-settings-div"];
  return hiddenFields.includes(fieldName);
}
