import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import Copyright from "components/Copyright/Copyright.js";
import TransmitContainer from "views/TransmitContainer/TransmitContainer.js";
import { removeElement, addStyle } from "utils/utils.js";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {
  const classes = useStyles();
  const { routes, tsService } = props;
  const [isGoogleIdpEnabled, setGoogleIdp] = useState(false);
  const id = "xmui-override";
  let googleIdp;
  const textContent = `:root { 
      --xmui-primary-color: ${routes[0].commands[0].user_association.branding.primary_color
        .replace("rgb(", "")
        .replace(")", "")};
      --xmui-secondary-color: ${routes[0].commands[0].user_association.branding.secondary_color
        .replace("rgb(", "")
        .replace(")", "")};
      };
  }`;

  useEffect(() => {
    if (document.getElementById("xmui-override") !== undefined) {
      removeElement("xmui-override");
      addStyle(id, textContent);
    }
    getGoogleIDP();
  });

  const getGoogleIDP = () => {
    routes[0].commands[0].user_association.actions.map((prop, key) => {
      if (prop.type === "google_idp") {
        console.log("GOOGLE IDP:", prop.policy_id);
        googleIdp = prop.policy_id;
        setGoogleIdp(prop.visible);
      }
    });
  };

  const loginWithGoogle = (policy_type) => {
    console.log(policy_type);
    var tsprops = tsService.buildTsProps(
      policy_type,
      routes[0].commands[0].user_association.actions
    );
    tsprops.loginContainer.style.display = "none";
    tsService
      .invokeAnonymousPolicy(policy_type, tsprops.params, tsprops.clientContext)
      .then((successfulAuth) => {
        console.log(
          `TS journey - ${tsprops.policyId} successfully executed : \n ` +
            successfulAuth.getToken()
        );
        const token = successfulAuth.getToken();
        tsService.updateSessionToken(token);
        tsService.onLogin();
      })
      .catch((err) => {
        console.log(
          `TS journey - ${tsprops.policyId}  execution failed : \n ` + err
        );
        tsService.handleAuthError(err).then((msg) => {
          if (msg === "retry") {
            doLogin(policy_type);
          } else {
            tsService.showError(err);
            tsService.onCancel();
          }
        });
      });
  };

  const loginWithQrCode = (policy_type) => {
    var tsprops = tsService.buildTsProps(
      policy_type,
      routes[0].commands[0].user_association.actions
    );
    tsprops.loginContainer.style.display = "none";
    tsService
      .invokeAnonymousPolicy(
        tsprops.policyId,
        tsprops.params,
        tsprops.clientContext
      )
      .then((successfulAuth) => {
        console.log(
          `TS journey - ${tsprops.policyId} successfully executed : \n ` +
            successfulAuth.getToken()
        );
        const token = successfulAuth.getToken();
        tsService.updateSessionToken(token);
        tsService.onLogin();
      })
      .catch((err) => {
        console.log(
          `TS journey - ${tsprops.policyId}  execution failed : \n ` + err
        );
        tsService.handleAuthError(err).then((msg) => {
          if (msg === "retry") {
            doLogin(policy_type);
          } else {
            tsService.showError(err);
            tsService.onCancel();
          }
        });
      });
  };

  const doLogin = (policy_type) => {
    var tsprops = tsService.buildTsProps(
      policy_type,
      routes[0].commands[0].user_association.actions
    );

    if (policy_type === "user_login") {
      if (!tsprops.username || tsprops.username.length === 0) {
        alert("Please fill in your userId to authenticate");
        return;
      }
    }

    tsprops.loginContainer.style.display = "none";

    tsService
      .startAuth(
        tsprops.username,
        tsprops.policyId,
        tsprops.params,
        tsprops.clientContext
      )
      .then((successfulAuth) => {
        console.log(
          `TS journey - ${tsprops.policyId} successfully executed : \n ` +
            successfulAuth.getToken()
        );
        const token = successfulAuth.getToken();
        tsService.updateSessionToken(token);
        tsService.onLogin();
      })
      .catch((err) => {
        console.log(
          `TS journey - ${tsprops.policyId}  execution failed : \n ` + err
        );
        tsService.handleAuthError(err).then((msg) => {
          if (msg === "retry") {
            doLogin(policy_type);
          } else {
            tsService.showError(err);
            tsService.onCancel();
          }
        });
      });
  };

  return (
    <div id="sign-in-div">
      <TransmitContainer />
      <div id="loginContainer">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="User ID"
                name="username"
                autoComplete="email"
                autoFocus
              />
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => doLogin("user_login")}
              >
                Sign In
              </Button>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => loginWithQrCode("qr_bind")}
              >
                Login With QR Code
              </Button>
              {isGoogleIdpEnabled ? (
                <div
                  className="google-btn"
                  id="googleIDPLoginButton"
                  onClick={() => loginWithGoogle(googleIdp)}
                >
                  <div className="google-icon-wrapper">
                    <img
                      className="google-icon"
                      src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                    />
                  </div>
                  <p className="btn-text">
                    <b>Sign in with Google</b>
                  </p>
                </div>
              ) : null}
            </form>
          </div>
          <Box mt={8}>
            <Copyright routes={routes} />
          </Box>
        </Container>
      </div>
    </div>
  );
}

SignIn.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  bgColor: PropTypes.oneOf([
    "purple",
    "DarkGreen",
    "SlateGrey",
    "DodgerBlue",
    "blue",
    "green",
    "orange",
    "red",
  ]),
  tsService: PropTypes.object,
};
