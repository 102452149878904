import { defaultRoutes } from "demojson.js";

var routes;

export function loadRoutes() {
  if (localStorage.getItem("TS") !== ("undefined" && null)) {
    routes = JSON.parse(localStorage.getItem("TS"));
  } else {
    localStorage.setItem("TS", JSON.stringify(defaultRoutes));
    routes = defaultRoutes;
  }
}

loadRoutes();

export default routes;
