import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import SettingsFooter from "components/SettingsFooter/SettingsFooter.js";
import Checkbox from "@material-ui/core/Checkbox";
import {
  Grid,
  TextField,
  FormControlLabel,
  InputLabel,
  Select,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "8vw",
    },
  },
}));

export default function CustomActionSettings(props) {
  const {
    routes,
    handleIndustryChange,
    handleCustomActionTabNameChange,
    disableCustomActionField,
    handleCustomActionsToggle,
    industryList,
    select,
    resetSettings,
    handleFileImport,
    downloadSettings,
  } = props;
  const classes = useStyles();
  return (
    <div>
      <Grid>
        <GridContainer>
          <GridItem xs={"auto"} sm={"auto"} md={"auto"}>
            <Card
              variant="outlined"
              style={{
                marginLeft: "0.5vw",
              }}
            >
              <CardHeader>
                <InputLabel htmlFor="filled-age-native-simple">
                  Filter Industry Type
                </InputLabel>
                {
                  <GridContainer key={"select"}>
                    <GridItem xs={12} sm={12} md={10}>
                      <Select
                        native
                        labelid="select-label"
                        id="select"
                        value={select}
                        onChange={(event) =>
                          handleIndustryChange(event, routes)
                        }
                        style={{
                          minWidth: "74vw",
                        }}
                      >
                        {industryList.map((industry) => (
                          <option
                            key={"industry_select_" + industry}
                            value={industry}
                          >
                            {industry}
                          </option>
                        ))}
                      </Select>
                    </GridItem>
                  </GridContainer>
                }
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
      </Grid>
      <Grid>
        <GridContainer>
          <GridItem xs={"auto"} sm={"auto"} md={"auto"}>
            {routes[0].commands[0].user_association.custom_actions.map(
              (prop, key) => {
                if (prop.industry_type === select) {
                  if (prop.policy_id !== undefined) {
                    const textFields = [];
                    for (let key in prop) {
                      if (key !== "industry_type") {
                        textFields.push(
                          <TextField
                            key={
                              "text-custom-actions-key-" + prop.type + "-" + key
                            }
                            id={
                              "text-custom-actions-id-" + prop.type + "-" + key
                            }
                            label={key}
                            value={prop[key]}
                            onChange={(event) =>
                              handleCustomActionTabNameChange(
                                event,
                                routes,
                                prop.type,
                                key
                              )
                            }
                            fullWidth
                            margin="normal"
                            disabled={disableCustomActionField(key)}
                          />
                        );
                      }
                    }
                    return (
                      <Card
                        key={"card-ca-cb-" + prop.type}
                        variant="outlined"
                        style={{
                          marginLeft: "0.5vw",
                        }}
                      >
                        <CardBody>
                          <form
                            className={classes.root}
                            noValidate
                            autoComplete="off"
                          >
                            {textFields}
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={"cb-ca-" + prop.type}
                                  id={"cb-ca-" + prop.type}
                                  checked={prop.visible}
                                  onClick={() =>
                                    handleCustomActionsToggle(
                                      1,
                                      routes,
                                      prop.type
                                    )
                                  }
                                  style={{
                                    bottom: "-1rem",
                                  }}
                                />
                              }
                              style={{
                                width: "auto",
                              }}
                            />
                          </form>
                        </CardBody>
                      </Card>
                    );
                  }
                }
              }
            )}
            <SettingsFooter
              resetSettings={resetSettings}
              handleFileImport={handleFileImport}
              downloadSettings={downloadSettings}
            ></SettingsFooter>
          </GridItem>
        </GridContainer>
      </Grid>
    </div>
  );
}

CustomActionSettings.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  handleIndustryChange: PropTypes.func,
  handleCustomActionTabNameChange: PropTypes.func,
  disableCustomActionField: PropTypes.func,
  handleCustomActionsToggle: PropTypes.func,
  industryList: PropTypes.arrayOf(PropTypes.string),
  select: PropTypes.string,
  resetSettings: PropTypes.func,
  handleFileImport: PropTypes.func,
  downloadSettings: PropTypes.func,
};
