import React, { useEffect } from "react";
import PropTypes from "prop-types";
import CustomAction from "components/CustomAction/CustomAction.js";
import Products from "components/ProductList/ProductList.js";
import Cart from "components/Cart/Cart.js";
import Checkout from "components/Checkout/Checkout.js";
import "./RetailCart.css";
import {
  showProducts,
  showCart,
  showCheckout,
  hideShoppingCart,
} from "utils/utils.js";

export default function RetailCart(props) {
  const [cart, setCart] = React.useState([]);
  const { routes, tsService } = props;
  const policy_type = "retail_cart";
  const cartUpdated = (cart) => {
    setCart(cart);
  };

  useEffect(() => {
    hideShoppingCart();
    showProducts();
  });

  return (
    <div>
      <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <div className="container">
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav">
                <button
                  className="nav-item nav-link cart-button"
                  style={{
                    backgroundColor:
                      routes[0].commands[0].user_association.branding
                        .primary_color,
                  }}
                  onClick={showProducts}
                >
                  Products
                </button>
                <button
                  className="nav-item nav-link cart-button"
                  style={{
                    backgroundColor:
                      routes[0].commands[0].user_association.branding
                        .primary_color,
                  }}
                  onClick={showCart}
                >
                  Cart
                </button>
                <button
                  className="nav-item nav-link cart-button checkout"
                  onClick={showCheckout}
                >
                  Checkout
                </button>
                }
              </div>
            </div>
          </div>
        </nav>
      </div>
      <div id="shopping-cart">
        <div id="products">
          <Products products={tsService.products} onCartUpdated={cartUpdated} />
        </div>
        <div id="cart">
          <Cart products={tsService.products} cart={cart} />
        </div>
        <div id="checkout">
          <Checkout />
        </div>
      </div>
    </div>
    /*
    <div>
      <CustomAction
        policy_type={policy_type}
        routes={routes}
        tsService={tsService}
      />
    </div>
    */
  );
}

RetailCart.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  tsService: PropTypes.object,
};
