import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Settings from "components/Settings/Settings.js";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "assets/img/sidebar-2.jpg";
import { components, industryList } from "utils/components.js";
import { getRoute, getSignInRoute } from "utils/utils.js";
import "./Home.css";

let ps;
const useStyles = makeStyles(styles);

function Home(props) {
  const { tsService } = props;
  const routes = tsService.routes;
  const classes = useStyles();
  const mainPanel = React.createRef();

  // states and functions
  const [image, setImage] = useState(bgImage);
  const [color, setColor] = useState("blue");
  const [fixedClasses, setFixedClasses] = useState("dropdown");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [state, setState] = useState(true);
  const [select, setSelect] = useState("");
  const [policy_type, setPolicyType] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    setSelect(routes[0].commands[0].industry);
  });

  const handlePolicyTypeChange = (policy) => {
    setPolicyType(policy);
  };

  const handleUsernameChange = (username) => {
    setUsername(username);
  };

  const switchRoutes = (
    <Switch>
      {/* add routes for main actions */}
      {routes[0].commands[0].user_association.actions.map((prop, key) => {
        if (prop.layout === "/home" && prop.policy_id !== undefined) {
          return components.map((components_prop) => {
            if (components_prop.policy_type === prop.type) {
              return (
                <Route
                  path={prop.layout + "/" + prop.type}
                  key={key}
                  render={(props) => (
                    <components_prop.component
                      {...props}
                      color={color}
                      routes={routes}
                      tsService={tsService}
                      policy_type={prop.type}
                      username={username}
                      handlePolicyTypeChange={handlePolicyTypeChange}
                      handleUsernameChange={handleUsernameChange}
                    />
                  )}
                />
              );
            }
          });
        }
        return null;
      })}
      {/* add routes for custom actions */}
      {routes[0].commands[0].user_association.custom_actions.map(
        (prop, key) => {
          if (prop.layout === "/home" && prop.policy_id !== undefined) {
            return components.map((components_prop) => {
              if (components_prop.policy_type === prop.type) {
                return (
                  <Route
                    path={prop.layout + "/" + prop.type}
                    key={key}
                    render={(props) => (
                      <components_prop.component
                        policy_type={prop.type}
                        routes={routes}
                        tsService={tsService}
                        {...props}
                        color={color}
                      />
                    )}
                  />
                );
              }
            });
          }
          return null;
        }
      )}
    </Switch>
  );

  const handleSelectChange = (selectValue) => {
    setSelect(selectValue);
  };
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleUpdates = () => {
    state === true ? setState(false) : setState(true);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  return (
    <div className={classes.wrapper} key={"cn-" + classes.wrapper}>
      <Sidebar
        select={select}
        industryList={industryList}
        routes={routes}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        tsService={tsService}
        policy_type={policy_type}
        username={username}
        {...props}
      />
      <div
        id={getSignInRoute() ? "main-panel-signin-div" : "main-panel-div"}
        className={classes.mainPanel}
        ref={mainPanel}
        key={"cn-" + classes.mainPanel}
        style={
          getSignInRoute()
            ? {
                backgroundImage:
                  select === "banking"
                    ? `url(${routes[0].commands[0].user_association.branding.banking_background})`
                    : select === "retail"
                    ? `url(${routes[0].commands[0].user_association.branding.retail_background})`
                    : null,
              }
            : {}
        }
      >
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer routes={routes} /> : null}
        <Settings
          tsService={tsService}
          select={select}
          industryList={industryList}
          routes={routes}
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
          handleUpdates={handleUpdates}
          handleSelectChange={handleSelectChange}
        />
      </div>
    </div>
  );
}

export default withRouter(Home);

Home.propTypes = {
  tsService: PropTypes.object,
};
