import SignIn from "views/Signin/Signin.js";
import TransmitContainer from "views/TransmitContainer/TransmitContainer.js";
import RetailCart from "views/RetailCart/RetailCart.js";
import BindID from "views/BindId/BindId.js";
import GenericCustomAction from "views/GenericCustomAction/GenericCustomAction.js";
import GenericMainAction from "views/GenericMainAction/GenericMainAction.js";
import ConfigureAuthenticators from "views/ConfigureAuthenticators/ConfigureAuthenticators.js";
import routes from "utils/routes.js";

export const components = [
  { policy_type: "add_payee", component: GenericCustomAction },
  { policy_type: "transfer", component: GenericCustomAction },
  { policy_type: "balance", component: GenericCustomAction },
  { policy_type: "preferences", component: GenericCustomAction },
  { policy_type: "pickup", component: GenericCustomAction },
  { policy_type: "coupons", component: GenericCustomAction },
  { policy_type: "promotions", component: GenericCustomAction },
  { policy_type: "cart", component: GenericCustomAction },
  { policy_type: "retail_cart", component: RetailCart },
  { policy_type: "forgot_username", component: GenericMainAction },
  { policy_type: "create_account", component: GenericMainAction },
  { policy_type: "user_login", component: SignIn },
  { policy_type: "qr_bind", component: GenericMainAction },
  { policy_type: "account_status", component: GenericCustomAction },
  { policy_type: "my_activity", component: GenericCustomAction },
  { policy_type: "contact_support", component: GenericMainAction },
  { policy_type: "transmitContainer", component: TransmitContainer },
  { policy_type: "bind_id", component: BindID },
  { policy_type: "generic_retail_1", component: GenericCustomAction },
  { policy_type: "generic_retail_2", component: GenericCustomAction },
  { policy_type: "generic_retail_3", component: GenericCustomAction },
  { policy_type: "generic_retail_4", component: GenericCustomAction },
  { policy_type: "generic_retail_5", component: GenericCustomAction },
  { policy_type: "generic_banking_1", component: GenericCustomAction },
  { policy_type: "generic_banking_2", component: GenericCustomAction },
  { policy_type: "generic_banking_3", component: GenericCustomAction },
  { policy_type: "generic_banking_4", component: GenericCustomAction },
  { policy_type: "generic_banking_5", component: GenericCustomAction },
  { policy_type: "generic_banking_6", component: GenericCustomAction },
  { policy_type: "generic_banking_7", component: GenericCustomAction },
  { policy_type: "generic_preauth_1", component: GenericMainAction },
  { policy_type: "generic_preauth_2", component: GenericMainAction },
  { policy_type: "generic_preauth_3", component: GenericMainAction },
  { policy_type: "generic_preauth_4", component: GenericMainAction },
  {
    policy_type: "configure_authenticators",
    component: ConfigureAuthenticators,
  },
];

export const industryList = buildIndustrySelectList(routes);

function buildIndustrySelectList(routes) {
  const industries = [];
  routes[0].commands[0].user_association.custom_actions.map((prop) => {
    if (
      industries.includes(prop.industry_type) === false &&
      prop.industry_type !== undefined
    ) {
      industries.push(prop.industry_type);
    }
  });
  return industries;
}
