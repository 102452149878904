import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

export default function Copyright(props) {
  const { routes } = props;
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit">
        {routes[0].commands[0].user_association.branding.client_name}
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

Copyright.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
};
