import React from "react";
import PropTypes from "prop-types";
import Card from "components/Card/Card.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import { TextField } from "@material-ui/core";

export default function SettingsFooter(props) {
  const { resetSettings, handleFileImport, downloadSettings } = props;
  const buttonColor = "#29648A";
  return (
    <Card
      style={{
        marginLeft: "0.5vw",
      }}
    >
      <CardFooter>
        <Button
          style={{ backgroundColor: buttonColor }}
          onClick={resetSettings}
        >
          Reset Settings
        </Button>
        <Button
          style={{ backgroundColor: buttonColor }}
          onClick={handleFileImport}
        >
          Import Settings
        </Button>
        <TextField
          id={"file-input"}
          type={"file"}
          name={"name"}
          style={{ display: "none" }}
        />
        <Button
          style={{ backgroundColor: buttonColor }}
          onClick={downloadSettings}
        >
          Export Settings
        </Button>
      </CardFooter>
    </Card>
  );
}

SettingsFooter.propTypes = {
  resetSettings: PropTypes.func,
  handleFileImport: PropTypes.func,
  downloadSettings: PropTypes.func,
};
