import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TransmitContainer from "views/TransmitContainer/TransmitContainer.js";
import { removeElement, addStyle } from "utils/utils.js";
export default function MainAction(props) {
  const { routes, tsService, policy_type } = props;
  const id = "xmui-override";
  const textContent = `:root { 
      --xmui-primary-color: ${routes[0].commands[0].user_association.branding.primary_color
        .replace("rgb(", "")
        .replace(")", "")};
      --xmui-secondary-color: ${routes[0].commands[0].user_association.branding.secondary_color
        .replace("rgb(", "")
        .replace(")", "")};
      };
  }`;
  useEffect(() => {
    let invoke_type = tsService.getInvokeType(
      routes[0].commands[0].user_association.actions,
      policy_type
    );
    switch (invoke_type) {
      case "anonymous":
        invokeAnonymousPolicy(policy_type);
        break;
      case "authenticate":
        authenticate(policy_type);
        break;
      case "invoke":
        invokePolicy(policy_type);
        break;
      default:
        console.log("no such policy found");
    }
    if (document.getElementById("xmui-override") !== undefined) {
      removeElement("xmui-override");
      addStyle(id, textContent);
    }
  });

  const invokeAnonymousPolicy = (policy_type) => {
    var tsprops = tsService.buildTsProps(
      policy_type,
      routes[0].commands[0].user_association.actions
    );
    tsService.cancelCurrentRunningControlFlow().then((result) => {
      tsService
        .invokeAnonymousPolicy(
          tsprops.policyId,
          tsprops.params,
          tsprops.clientContext
        )
        .then((successfulAuth) => {
          console.log(
            `TS journey - ${tsprops.policyId} successfully executed : \n `
          );
          const token = successfulAuth.getToken();
          tsService.updateSessionToken(token);
          tsService.onLogin();
        })
        .catch((err) => {
          console.log(
            `TS journey - ${tsprops.policyId}  execution failed : \n ` + err
          );
          tsService.handleAuthError(err).then((msg) => {
            if (msg === "retry") {
              invokeAnonymousPolicy(policy_type);
            } else {
              tsService.showError(err);
              tsService.onCancel();
            }
          });
        });
    });
  };

  const invokePolicy = (policy_type) => {
    if (!tsService.getSessionToken()) {
      if (window.location.href.indexOf("/home/user_login") === -1) {
        tsService.onCancel();
      }
    }

    var tsprops = tsService.buildTsProps(
      policy_type,
      routes[0].commands[0].user_association.custom_actions
    );

    tsService
      .cancelCurrentRunningControlFlow()
      .then((result) => {
        tsService
          .invokePolicy(tsprops.policyId, tsprops.params, tsprops.clientContext)
          .then((result) => {
            console.log(
              `TS journey - ${tsprops.policyId} successfully executed`
            );
            alert(`TS journey - ${tsprops.policyId} successfully executed`);
          })
          .catch((err) => {
            console.log(
              `TS journey - ${tsprops.policyId}  execution failed : \n ` + err
            );
            tsService.handleAuthError(err).then((msg) => {
              if (msg === "retry") {
                invokePolicy(policy_type);
              } else {
                tsService.showError(err);
              }
            });
          });
      })
      .catch((err) => {
        console.log(
          `TS journey - ${tsprops.policyId}  execution failed : \n ` + err
        );
        tsService.handleAuthError(err).then((msg) => {
          if (msg === "retry") {
            invokePolicy(policy_type);
          } else {
            tsService.showError(err);
          }
        });
      });
  };

  const authenticate = (policy_type) => {
    var tsprops = tsService.buildTsProps(
      policy_type,
      routes[0].commands[0].user_association.actions
    );

    if (policy_type === "user_login") {
      if (!tsprops.username || tsprops.username.length === 0) {
        alert("Please fill in your userId to authenticate");
        return;
      }
    }

    tsService
      .startAuth(
        tsprops.username,
        tsprops.policyId,
        tsprops.params,
        tsprops.clientContext
      )
      .then((result) => {
        console.log(
          `TS journey - ${tsprops.policyId} successfully executed : \n ` +
            result.getToken()
        );
        const token = result.getToken();
        tsService.updateSessionToken(token);
        tsService.onLogin();
      })
      .catch((err) => {
        console.log(
          `TS journey - ${tsprops.policyId}  execution failed : \n ` + err
        );
        alert(err);
        tsService.handleAuthError(err).then((msg) => {
          if (msg === "retry") {
            authenticate(policy_type);
          } else {
            tsService.showError(err);
            tsService.onCancel();
          }
        });
      });
  };
  return (
    <div>
      <TransmitContainer />
    </div>
  );
}

MainAction.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  tsService: PropTypes.object,
  policy_type: PropTypes.string,
};
