import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { routes } = props;
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <p className={classes.right}>
          <span>
            &copy; {1900 + new Date().getYear()}{" "}
            <a href="#" target="_blank" className={classes.a}>
              {routes[0].commands[0].user_association.branding.client_name}
            </a>
          </span>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
};
