import React from "react";
import PropTypes from "prop-types";
import ProductItem from "components/ProductItem/ProductItem.js";
import { showCart } from "utils/utils.js";

export default class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: props.products,
    };
  }

  render() {
    const { products } = this.state;
    const { onCartUpdated } = this.props;
    return (
      <div className=" container">
        <h3 className="card-title">List of Available Products</h3>
        <hr />
        {products.map((product, index) => (
          <ProductItem
            product={product}
            key={index}
            onCartUpdated={onCartUpdated}
          />
        ))}
        <hr />

        <button className="btn btn-success float-right">Checkout</button>

        <button
          className="btn btn-primary float-right"
          style={{ marginRight: "10px" }}
          onClick={showCart}
        >
          View Cart
        </button>

        <br />
        <br />
        <br />
      </div>
    );
  }
}

ProductList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  onCartUpdated: PropTypes.func,
};
