import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "components/Navbars/Navbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Settings from "components/Settings/Settings.js";
import Dashboard from "views/Dashboard/Dashboard";
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";
import bgImage from "assets/img/sidebar-2.jpg";
import { components, industryList } from "utils/components.js";
import { getRoute, getDashboardRoute } from "utils/utils.js";

let ps;
const useStyles = makeStyles(styles);

function Admin(props) {
  const { tsService } = props;
  const routes = tsService.routes;
  const classes = useStyles();
  const mainPanel = React.createRef();

  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [state, setState] = React.useState(true);
  const [select, setSelect] = React.useState(routes[0].commands[0].industry);

  const switchRoutes = (
    <Switch>
      {/* add routes for main actions */}
      {routes[0].commands[0].user_association.actions.map((prop, key) => {
        if (prop.layout === "/admin" && prop.policy_id !== undefined) {
          return components.map((components_prop) => {
            if (components_prop.policy_type === prop.type) {
              return (
                <Route
                  path={prop.layout + "/" + prop.type}
                  key={key}
                  render={(props) => (
                    <components_prop.component
                      policy_type={prop.type}
                      routes={routes}
                      tsService={tsService}
                      handleUpdates={handleUpdates}
                      {...props}
                      color={color}
                    />
                  )}
                />
              );
            }
          });
        }
        return null;
      })}
      {/* add routes for custom actions */}
      {routes[0].commands[0].user_association.custom_actions.map(
        (prop, key) => {
          if (prop.layout === "/admin" && prop.policy_id !== undefined) {
            return components.map((components_prop) => {
              if (components_prop.policy_type === prop.type) {
                return (
                  <Route
                    path={prop.layout + "/" + prop.type}
                    key={key}
                    render={(props) => (
                      <components_prop.component
                        policy_type={prop.type}
                        routes={routes}
                        tsService={tsService}
                        handleUpdates={handleUpdates}
                        {...props}
                        color={color}
                      />
                    )}
                  />
                );
              }
            });
          }
          return null;
        }
      )}
      <Redirect from="/admin" to="/admin/dashboard" />
    </Switch>
  );

  const handleSelectChange = (selectValue) => {
    setSelect(selectValue);
  };
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleUpdates = () => {
    state === true ? setState(false) : setState(true);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      <Sidebar
        select={select}
        industryList={industryList}
        routes={routes}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        handleSelectChange={handleSelectChange}
        open={mobileOpen}
        color={color}
        tsService={tsService}
        handleUpdates={handleUpdates}
        {...props}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          tsService={tsService}
          routes={routes}
          handleDrawerToggle={handleDrawerToggle}
          {...props}
        />
        {getDashboardRoute() ? (
          routes[0].commands[0].industry === "banking" ? (
            <div className={classes.content}>
              <Dashboard tsService={tsService} />
            </div>
          ) : (
            <div className={classes.content}>RETAIL</div>
          )
        ) : getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        ) : (
          <div className={classes.map}>{switchRoutes}</div>
        )}
        {getRoute() ? <Footer routes={routes} /> : null}
        <Settings
          tsService={tsService}
          select={select}
          industryList={industryList}
          routes={routes}
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          bgColor={color}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
          handleUpdates={handleUpdates}
          handleSelectChange={handleSelectChange}
        />
      </div>
    </div>
  );
}

export default withRouter(Admin);

Admin.propTypes = {
  tsService: PropTypes.object,
};
