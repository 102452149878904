export const defaultProducts = [
  {
    id: 1,
    name: "Trigger Spray Nozzle",
    available_quantity: 5,
    price: 9.99,
    description:
      "The spray nozzle features jet, flat spray, shower, angle spray, mist and soaker patterns to handle a wide range of washing and cleaning jobs. The nozzle is constructed of durable zinc and polypropylene.",
  },

  {
    id: 2,
    name: "5/8 In. X 100 Ft. Heavy Duty Garden Hose",
    available_quantity: 7,
    price: 31.99,
    description:
      "This 100 ft. heavy duty garden hose is constructed with an internal cross-weave mesh so that the hose coils easily without kinking. The tough 5-ply construction makes this garden hose ideal for yardwork and general household maintenance.",
  },

  {
    id: 3,
    name: "Two-Way Y Hose Connector",
    available_quantity: 0,
    price: 3.99,
    description:
      "This two-way Y hose connector connects two hoses to one faucet. Constructed of rust-proof aluminum, this Y hose connector is built with individual stainless steel valves for each hose.",
  },

  {
    id: 4,
    name: "Garden Hose Quick Coupler Set 6 Pc.",
    available_quantity: 4,
    price: 7.99,
    description:
      "This Garden Hose Quick Coupler Set makes it easy to attach and remove spray nozzles, sprinklers and other accessories. This set includes 4 male and 2 female couplers to accommodate multiple hoses and connections.",
  },
];
