import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { Grid, TextField } from "@material-ui/core";
import SettingsFooter from "components/SettingsFooter/SettingsFooter.js";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "8vw",
    },
  },
}));

export default function ConnectionSettings(props) {
  const {
    routes,
    handleConnectionSettingsChanges,
    resetSettings,
    handleFileImport,
    downloadSettings,
  } = props;
  const classes = useStyles();

  return (
    <div>
      <Grid>
        <GridContainer>
          <GridItem xs={"auto"} sm={"auto"} md={"auto"}>
            <Card
              variant="outlined"
              style={{
                marginLeft: "0.5vw",
              }}
            >
              <CardBody style={{ width: "74.5vw" }}>
                <form
                  className={classes.root}
                  noValidate
                  autoComplete="off"
                ></form>
                {Object.entries(routes[0].commands[0].settings).map((key) => {
                  return (
                    <TextField
                      key={"text-branding-key-" + key[0]}
                      id={"text-branding-id-" + key[0]}
                      label={key[0]}
                      value={key[1]}
                      onChange={(event) =>
                        handleConnectionSettingsChanges(event, routes, key[0])
                      }
                      fullWidth
                      margin="normal"
                    />
                  );
                })}
              </CardBody>
            </Card>
            <SettingsFooter
              resetSettings={resetSettings}
              handleFileImport={handleFileImport}
              downloadSettings={downloadSettings}
            ></SettingsFooter>
          </GridItem>
        </GridContainer>
      </Grid>
    </div>
  );
}

ConnectionSettings.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  handleConnectionSettingsChanges: PropTypes.func,
  resetSettings: PropTypes.func,
  handleFileImport: PropTypes.func,
  downloadSettings: PropTypes.func,
};
