import React from "react";
import PropTypes from "prop-types";
import CustomAction from "components/CustomAction/CustomAction.js";

export default function GenericCustomAction(props) {
  const { routes, tsService, policy_type } = props;

  return (
    <div>
      <CustomAction
        policy_type={policy_type}
        routes={routes}
        tsService={tsService}
      />
    </div>
  );
}

GenericCustomAction.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  tsService: PropTypes.object,
  policy_type: PropTypes.string,
};
