import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox";
import { Grid, TextField, FormControlLabel } from "@material-ui/core";
import SettingsFooter from "components/SettingsFooter/SettingsFooter.js";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "8vw",
    },
  },
}));

export default function MainActionSettings(props) {
  const {
    routes,
    handleMainActionsToggle,
    handleMainActionTabNameChange,
    disableMainActionField,
    disableMainActionUserLoginInvokeTypeField,
    resetSettings,
    handleFileImport,
    downloadSettings,
  } = props;
  const classes = useStyles();
  return (
    <Grid>
      <GridContainer>
        <GridItem xs={"auto"} sm={"auto"} md={"auto"}>
          {routes[0].commands[0].user_association.actions.map((prop, key) => {
            if (prop.policy_id !== undefined) {
              const textFields = [];
              for (let key in prop) {
                textFields.push(
                  <TextField
                    key={"text-main-action-key-" + prop.type + "-" + key}
                    id={"text-main-action-id-" + prop.type + "-" + key}
                    label={key}
                    value={prop[key]}
                    onChange={(event) =>
                      handleMainActionTabNameChange(
                        event,
                        routes,
                        prop.type,
                        key
                      )
                    }
                    fullWidth
                    margin="normal"
                    disabled={
                      disableMainActionField(key) ||
                      disableMainActionUserLoginInvokeTypeField(
                        prop.type,
                        prop.invoke_type,
                        key
                      )
                    }
                  />
                );
              }
              return (
                <Card
                  key={"card-ma-" + prop.type}
                  variant="outlined"
                  style={{
                    marginLeft: "0.5vw",
                  }}
                >
                  <CardBody>
                    <form
                      className={classes.root}
                      noValidate
                      autoComplete="off"
                    >
                      {textFields}
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={"cb-ma-" + prop.type}
                            id={"cb-ma-" + prop.type}
                            checked={prop.visible}
                            onClick={() =>
                              handleMainActionsToggle(1, routes, prop.type)
                            }
                            style={{
                              bottom: "-1rem",
                            }}
                          />
                        }
                        style={{
                          width: "auto",
                        }}
                      />
                    </form>
                  </CardBody>
                </Card>
              );
            }
          })}
          <SettingsFooter
            resetSettings={resetSettings}
            handleFileImport={handleFileImport}
            downloadSettings={downloadSettings}
          ></SettingsFooter>
        </GridItem>
      </GridContainer>
    </Grid>
  );
}

MainActionSettings.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  handleMainActionsToggle: PropTypes.func,
  handleMainActionTabNameChange: PropTypes.func,
  disableMainActionField: PropTypes.func,
  disableMainActionUserLoginInvokeTypeField: PropTypes.func,
  resetSettings: PropTypes.func,
  handleFileImport: PropTypes.func,
  downloadSettings: PropTypes.func,
};
