import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CartItem from "components/CartItem/CartItem.js";
import { getCart, getCartCount } from "utils/utils.js";

export default class Cart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: props.products,
      total: 0,
      cartContent: [],
      cartCount: 0,
    };
  }

  componentDidMount() {
    console.log("CDM");
    let cart = getCart();
    let cartCount = getCartCount();
    this.setState({ cartCount }); // this triggers CDU
    console.log({ cart, cartCount });
    this.loadData("CDM");
  }
  componentDidUpdate() {
    const { cartCount } = this.state;
    let cartUpdatedCount = getCartCount();

    if (cartUpdatedCount !== cartCount) {
      this.loadData("CDU", { cartUpdatedCount, cartCount });
      this.setState({ cartCount: cartUpdatedCount });
    }
    console.log("CDU");
  }

  loadData = (reason) => {
    console.log("LOAD DATA BECAUSE", reason);

    let cart = getCart(),
      id = null,
      cartContent = [];
    if (!cart) {
      console.error("NOTHING IN CART");
    } else {
      console.log("CART", cart);
    }
    const products = [...this.state.products];
    if (products && products.length && cart) {
      products.forEach((item) => {
        if (cart[item.id]) {
          item.quantity = cart[item.id];
          cartContent.push(item);
        }
      });

      let total = 0;
      for (var i = 0; i < cartContent.length; i++) {
        total +=
          parseFloat(cartContent[i].price) *
          parseFloat(cartContent[i].quantity);
      }
      console.log("total", total);
      this.setState({ cartContent, total });
    }
  };

  removeFromCart = (cartItem) => {
    console.log("cartItem", cartItem);

    const cartContent = [...this.state.cartContent];

    let newCartContent = cartContent.filter((item) => item.id !== cartItem.id);
    let cart = getCart();
    delete cart[cartItem.id.toString()];
    localStorage.setItem("cart", JSON.stringify(cart));

    let total =
      this.state.total -
      parseFloat(cartItem.quantity) * parseFloat(cartItem.price);
    this.setState({ cartContent: newCartContent, total });
  };

  clearCart = () => {
    localStorage.removeItem("cart");
    this.setState({ cartContent: [] });
  };

  render() {
    const { total, cartContent } = this.state;
    console.log("cartContent", cartContent);
    return (
      <div className=" container">
        <h3 className="card-title">Cart</h3>
        <hr />
        {cartContent.map((product, index) => (
          <CartItem
            product={product}
            remove={this.removeFromCart}
            key={index}
          />
        ))}{" "}
        <hr />
        {cartContent.length ? (
          <div>
            <h4>
              <small>Total Amount: </small>
              <span className="float-right text-primary">${total}</span>
            </h4>
            <hr />
          </div>
        ) : (
          ""
        )}
        {!cartContent.length ? (
          <h3 className="text-warning">No item on the cart</h3>
        ) : (
          ""
        )}
        <Link to="/checkout">
          <button className="btn btn-success float-right">Checkout</button>
        </Link>
        <button
          className="btn btn-danger float-right"
          onClick={this.clearCart}
          style={{ marginRight: "10px" }}
        >
          Clear Cart
        </button>
        <br />
        <br />
        <br />
      </div>
    );
  }
}

Cart.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
};
