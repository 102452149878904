import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();
  const { select, color, image, routes, tsService } = props;

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  var main_actions = (
    <List className={classes.list}>
      {routes[0].commands[0].user_association.actions.map((prop, key) => {
        if (activeRoute(prop.layout)) {
          if (prop.visible && prop.policy_id !== undefined) {
            var activePro = " ";
            var listItemClasses;
            listItemClasses = classNames({
              [" " + classes[color]]: activeRoute(
                prop.layout + "/" + prop.type
              ),
            });
            const whiteFontClasses = classNames({
              [" " + classes.whiteFont]: activeRoute(
                prop.layout + "/" + prop.type
              ),
            });
            const buttonColor = activeRoute(prop.layout + "/" + prop.type)
              ? routes[0].commands[0].user_association.branding.primary_color
              : "";
            return (
              <NavLink
                to={prop.layout + "/" + prop.type}
                className={activePro + classes.item}
                activeClassName="active"
                key={key}
              >
                <ListItem
                  button
                  className={classes.itemLink + listItemClasses}
                  style={{ backgroundColor: buttonColor }}
                >
                  {typeof prop.icon === "string" ? (
                    <Icon
                      className={classNames(classes.itemIcon, whiteFontClasses)}
                    >
                      {prop.icon}
                    </Icon>
                  ) : (
                    <prop.icon
                      className={classNames(classes.itemIcon, whiteFontClasses)}
                    />
                  )}
                  <ListItemText
                    primary={prop.label}
                    className={classNames(classes.itemText, whiteFontClasses)}
                    disableTypography={true}
                  />
                </ListItem>
              </NavLink>
            );
          }
        }
      })}
    </List>
  );
  var links = (
    <List className={classes.list}>
      {routes[0].commands[0].user_association.custom_actions.map(
        (prop, key) => {
          if (prop.industry_type === select) {
            if (activeRoute(prop.layout)) {
              if (prop.visible) {
                var activePro = " ";
                var listItemClasses;
                listItemClasses = classNames({
                  [" " + classes[color]]: activeRoute(
                    prop.layout + "/" + prop.type
                  ),
                });
                const whiteFontClasses = classNames({
                  [" " + classes.whiteFont]: activeRoute(
                    prop.layout + "/" + prop.type
                  ),
                });
                const buttonColor = activeRoute(prop.layout + "/" + prop.type)
                  ? routes[0].commands[0].user_association.branding
                      .primary_color
                  : "";
                return (
                  <NavLink
                    to={prop.layout + "/" + prop.type}
                    className={activePro + classes.item}
                    activeClassName="active"
                    key={key}
                  >
                    <ListItem
                      button
                      className={classes.itemLink + listItemClasses}
                      style={{ backgroundColor: buttonColor }}
                    >
                      {typeof prop.icon === "string" ? (
                        <Icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses
                          )}
                        >
                          {prop.icon}
                        </Icon>
                      ) : (
                        <prop.icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses
                          )}
                        />
                      )}
                      <ListItemText
                        primary={prop.label}
                        className={classNames(
                          classes.itemText,
                          whiteFontClasses
                        )}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                );
              }
            }
          }
        }
      )}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a href="#" className={classNames(classes.logoLink)} target="_blank">
        <div className={classes.logoImage}>
          <img
            src={routes[0].commands[0].user_association.branding.logo}
            alt="logo"
            className={classes.img}
          />
        </div>
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {<AdminNavbarLinks tsService={tsService} />}
            {links}
            {main_actions}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={"left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper),
          }}
        >
          <div>{brand}</div>
          <div className={classes.sidebarWrapper}>
            {links}
            {main_actions}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf([
    "purple",
    "DarkGreen",
    "SlateGrey",
    "DodgerBlue",
    "blue",
    "green",
    "orange",
    "red",
  ]),
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  industryList: PropTypes.arrayOf(PropTypes.string),
  select: PropTypes.string,
  tsService: PropTypes.object,
};
