import { defaultProducts } from "productsjson.js";

var products;

export function loadProducts() {
  if (localStorage.getItem("products") !== ("undefined" && null)) {
    products = JSON.parse(localStorage.getItem("products"));
  } else {
    localStorage.setItem("products", JSON.stringify(defaultProducts));
    products = defaultProducts;
  }
}

loadProducts();

export default products;
