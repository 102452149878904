import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { loadRoutes } from "utils/routes.js";
import { loadProducts } from "utils/products.js";
import ConnectionSettings from "components/ConnectionSettings/ConnectionSettings.js";
import BrandingSettings from "components/BrandingSettings/BrandingSettings.js";
import MainActionSettings from "components/MainActionSettings/MainActionSettings.js";
import CustomActionSettings from "components/CustomActionSettings/CustomActionSettings.js";
import BindIDSettings from "components/BindIDSettings/BindIDSettings.js";
import ProductSettings from "components/ProductSettings/ProductSettings.js";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "components/Settings/Settings.css";

export default function Settings(props) {
  const { tsService, routes, industryList, select, handleUpdates } = props;
  const [customActionsChecked, setCustomActionsChecked] = React.useState([]);
  const [mainActionsChecked, setMainActionsChecked] = React.useState([]);

  const handleIndustryChange = (event, routes) => {
    routes[0].commands[0].industry = event.target.value;
    localStorage.setItem("TS", JSON.stringify(routes));
    props.handleSelectChange(event.target.value);
  };

  const handleCustomActionsToggle = (value, routes, name) => {
    routes[0].commands[0].user_association.custom_actions.map((prop) => {
      if (prop.type === name) {
        prop.visible = !prop.visible;
      }
    });
    localStorage.setItem("TS", JSON.stringify(routes));
    const currentIndex = customActionsChecked.indexOf(value);
    const newChecked = [...customActionsChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCustomActionsChecked(newChecked);
    handleUpdates();
  };

  const handleMainActionsToggle = (value, routes, name) => {
    routes[0].commands[0].user_association.actions.map((prop) => {
      if (prop.type === name) {
        prop.visible = !prop.visible;
      }
    });
    localStorage.setItem("TS", JSON.stringify(routes));
    const currentIndex = mainActionsChecked.indexOf(value);
    const newChecked = [...mainActionsChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setMainActionsChecked(newChecked);
    handleUpdates();
  };

  const handleCustomActionTabNameChange = (
    event,
    routes,
    type,
    prop_to_update
  ) => {
    routes[0].commands[0].user_association.custom_actions.map((prop) => {
      if (prop.type === type) {
        prop[prop_to_update] = event.target.value;
        localStorage.setItem("TS", JSON.stringify(routes));
      }
    });
    handleUpdates();
  };

  const handleMainActionTabNameChange = (
    event,
    routes,
    type,
    prop_to_update
  ) => {
    routes[0].commands[0].user_association.actions.map((prop) => {
      if (prop.type === type) {
        prop[prop_to_update] = event.target.value;
        localStorage.setItem("TS", JSON.stringify(routes));
      }
    });
    handleUpdates();
  };

  const handleBrandingChanges = (event, routes, key) => {
    for (let brandingOption in routes[0].commands[0].user_association
      .branding) {
      if (brandingOption === key) {
        routes[0].commands[0].user_association.branding[key] =
          event.target.value;
        localStorage.setItem("TS", JSON.stringify(routes));
      }
    }
    handleUpdates();
  };

  const handleConnectionSettingsChanges = (event, routes, key) => {
    for (let setting in routes[0].commands[0].settings) {
      if (setting === key) {
        routes[0].commands[0].settings[key] = event.target.value;
        localStorage.setItem("TS", JSON.stringify(routes));
      }
    }
    handleUpdates();
  };

  const handleBindIDSettingsChanges = (event, routes, key) => {
    for (let setting in routes[0].commands[0].bind_id) {
      if (setting === key) {
        routes[0].commands[0].bind_id[key] = event.target.value;
        localStorage.setItem("TS", JSON.stringify(routes));
      }
    }
    handleUpdates();
  };

  const handleProductSettingsChanges = (event, products, id, el) => {
    for (let setting in products) {
      console.log(products[setting]);
      if (products[setting].id === id) {
        products[setting][el] = event.target.value;
        localStorage.setItem("products", JSON.stringify(products));
      }
    }
    handleUpdates();
  };

  const resetSettings = () => {
    localStorage.clear();
    loadRoutes();
    loadProducts();
    window.location.reload();
  };

  const downloadSettings = () => {
    var fileName = window.prompt("Please enter file name", "ts_demo.json");
    if (fileName !== null && fileName !== "") {
      var contentType = "text/plain";
      var content = localStorage.getItem("TS");
      var a = document.createElement("a");
      var file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
  };

  const handleClick = () => {
    props.handleFixedClick();
  };

  const handleFileImport = () => {
    const fileSelector = document.getElementById("file-input");
    fileSelector.addEventListener("change", (event) => {
      const fileList = event.target.files;
      readFileContent(fileList[0]);
    });
    document.getElementById("file-input").click();
  };

  const readFileContent = (file) => {
    var reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = function(evt) {
      localStorage.setItem("TS", evt.target.result);
      loadRoutes();
      window.location.reload();
    };
  };

  const disableMainActionUserLoginInvokeTypeField = (
    type,
    invoke_type,
    label
  ) => {
    return (
      type === "user_login" &&
      invoke_type === "authenticate" &&
      label === "invoke_type"
    );
  };

  const disableCustomActionField = (fieldName) => {
    const disabledFields = ["type", "invoke_type"];
    return disabledFields.includes(fieldName);
  };

  const disableMainActionField = (fieldName) => {
    const disabledFields = ["type", "invoke_type"];
    return disabledFields.includes(fieldName);
  };

  const isMultilineField = (fieldName) => {
    const multilineFields = ["xmui"];
    return multilineFields.includes(fieldName);
  };

  return (
    <div className={classnames("settings")}>
      <div id="fixedPluginClasses" className={props.fixedClasses}>
        <div onClick={handleClick}>
          <i className="fa fa-cog fa-2x" />
        </div>
        <div>
          <ul className="dropdown-menu">
            <Tabs>
              <div>
                <TabList>
                  <Tab>Connection</Tab>
                  <Tab>Bind ID</Tab>
                  <Tab>Branding</Tab>
                  <Tab>Preauth Actions</Tab>
                  <Tab>Authenticated Actions</Tab>
                  <Tab>Products</Tab>
                </TabList>
              </div>
              <div>
                <TabPanel>
                  <ConnectionSettings
                    handleConnectionSettingsChanges={
                      handleConnectionSettingsChanges
                    }
                    routes={routes}
                    resetSettings={resetSettings}
                    handleFileImport={handleFileImport}
                    downloadSettings={downloadSettings}
                  ></ConnectionSettings>
                </TabPanel>
                <TabPanel>
                  <BindIDSettings
                    handleBindIDSettingsChanges={handleBindIDSettingsChanges}
                    routes={routes}
                    resetSettings={resetSettings}
                    handleFileImport={handleFileImport}
                    downloadSettings={downloadSettings}
                  ></BindIDSettings>
                </TabPanel>
                <TabPanel>
                  <BrandingSettings
                    handleBrandingChanges={handleBrandingChanges}
                    routes={routes}
                    isMultilineField={isMultilineField}
                    resetSettings={resetSettings}
                    handleFileImport={handleFileImport}
                    downloadSettings={downloadSettings}
                    handleUpdates={handleUpdates}
                  ></BrandingSettings>
                </TabPanel>
                <TabPanel>
                  <MainActionSettings
                    handleMainActionsToggle={handleMainActionsToggle}
                    handleMainActionTabNameChange={
                      handleMainActionTabNameChange
                    }
                    disableMainActionField={disableMainActionField}
                    disableMainActionUserLoginInvokeTypeField={
                      disableMainActionUserLoginInvokeTypeField
                    }
                    routes={routes}
                    resetSettings={resetSettings}
                    handleFileImport={handleFileImport}
                    downloadSettings={downloadSettings}
                  ></MainActionSettings>
                </TabPanel>
                <TabPanel>
                  <CustomActionSettings
                    routes={routes}
                    handleIndustryChange={handleIndustryChange}
                    handleCustomActionTabNameChange={
                      handleCustomActionTabNameChange
                    }
                    disableCustomActionField={disableCustomActionField}
                    handleCustomActionsToggle={handleCustomActionsToggle}
                    industryList={industryList}
                    select={select}
                    resetSettings={resetSettings}
                    handleFileImport={handleFileImport}
                    downloadSettings={downloadSettings}
                  ></CustomActionSettings>
                </TabPanel>
                <TabPanel>
                  <ProductSettings
                    products={tsService.products}
                    handleProductSettingsChanges={handleProductSettingsChanges}
                  ></ProductSettings>
                </TabPanel>
              </div>
            </Tabs>
          </ul>
        </div>
      </div>
    </div>
  );
}

Settings.propTypes = {
  tsService: PropTypes.object,
  routes: PropTypes.arrayOf(PropTypes.object),
  industryList: PropTypes.arrayOf(PropTypes.string),
  bgImage: PropTypes.string,
  handleFixedClick: PropTypes.func,
  fixedClasses: PropTypes.string,
  bgColor: PropTypes.oneOf([
    "purple",
    "DarkGreen",
    "SlateGrey",
    "DodgerBlue",
    "blue",
    "green",
    "orange",
    "red",
  ]),
  handleColorClick: PropTypes.func,
  handleImageClick: PropTypes.func,
  handleUpdates: PropTypes.func,
  handleSelectChange: PropTypes.func,
  select: PropTypes.string,
};
