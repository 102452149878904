import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import "assets/css/material-dashboard-react.css?v=1.8.0";
import "bootstrap/dist/css/bootstrap.min.css";
import routes from "utils/routes.js";
import products from "utils/products.js";
import Admin from "layouts/Admin.js";
import Home from "layouts/Home.js";
import TransmitService from "ts/tsservice.js";

const hist = createBrowserHistory();
var tsService = new TransmitService(products, routes, "custom", hist);
tsService
  .startInit()
  .then((res) => {
    console.log("SDK VERSION:", tsService.sdk.getVersionInfo());
    // initialize BindID only if enabled
    routes[0].commands[0].user_association.actions.map((prop, key) => {
      if (prop.visible && prop.type === "bind_id") {
        console.log("BIND SERVICE ENABLED:", prop.visible);
        tsService.initializeBindIdSDK();
      }
    });
  })
  .catch((err) => {
    console.error(`Initialization error uccured: ${err.getMessage()}`);
  });

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/home">
        <Home tsService={tsService} />
      </Route>
      <Route path="/admin">
        <Admin tsService={tsService} />
      </Route>
      <Redirect from="/" to="/home/user_login" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
